import { Fragment } from "react";
import SettingsEventPalisis from "../components/Settings/SettingsEventPalisis";

const SettingsEventPalisisPage = () => {
  return (
    <Fragment>
      <h1>Prodotti palisis</h1>
      <SettingsEventPalisis></SettingsEventPalisis>
    </Fragment>
  );
};

export default SettingsEventPalisisPage;
