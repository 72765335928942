import classes from "./SideMenuHeader.module.css";

const SideMenuHeader = () => {
  return (
    <div className={classes.SideMenuHeader}>
      <img className={classes.SideMenuLogo} src="/logo.png" alt="Logo"></img>
      <strong>
        Bds <br /> Channel Manager
      </strong>
    </div>
  );
};

export default SideMenuHeader;
