import { useEffect, useState } from "react";

import { environment } from "../../config";
import useHttp from "../../hook/useHttp";
import {
  BdsEvent,
  PalisisEvent,
  SettingEventPalisis,
  SettingEventsPalisis,
} from "../../models/Settings";
import Spinner from "../UI/Spinner";
import SettingEventsPalisisTable from "./SettingsEventTablePalisis";

const SettingsEventPalisis = () => { 
  const { isLoading, error, sendRequest: fetchSettingEvents } = useHttp();
  const {
    isLoading: isLoadingSave,
    error: errorSave,
    sendRequest: saveSettingEvents,
  } = useHttp();
  const [settingEvents, setSettingEvents] = useState<SettingEventPalisis[]>(
    []
  );
  const [bdsEvents, setBdsEvents] = useState<BdsEvent[]>([]);
  const [palisisEvents, setBookingKitEvents] = useState<PalisisEvent[]>([]);
  const onSaveSettingEvents = (settingEventsForm: SettingEventPalisis[]) => {
    saveSettingEvents(
      {
        url: `${environment.API_ENDPOINT}/setting/eventsPalisis`,
        body: {
          settingEvents: settingEventsForm,
        },
        method: "PUT",
      },
      (response: { settingsEvent: SettingEventPalisis[] }) => {
        setSettingEvents(response.settingsEvent);
      }
    );
  };

  useEffect(() => {
    fetchSettingEvents(
      { url: `${environment.API_ENDPOINT}/setting/eventsPalisis` },
      (response: SettingEventsPalisis) => {
        console.log(response);
        const events = response.palisisEvents.map((event) => {
          const setting = response.settingsEvent.find(
            (item) => item.idPalisis === event.tour_id
          );
          if (setting) {
            return {
              ...setting,
              idPalisis: event.tour_id,
            };
          }
          return {
            idPartenzaBds: null,
            idProdottoBds: null,
            idPalisis: event.tour_id,
          };
        }) as SettingEventPalisis[];
        setSettingEvents(events);
        setBdsEvents(response.bdsEvents);
        setBookingKitEvents(response.palisisEvents);
      }
    );
  }, [fetchSettingEvents]);

  return (
    <div className="py-5">
      <div>
        {(error || errorSave) && "Si è verificato un problema."}
        {!error && !errorSave && (
          <Spinner showSpinner={isLoading || isLoadingSave}>
            <SettingEventsPalisisTable
              bdsEvents={bdsEvents}
              palisisEvents={palisisEvents}
              settingEvents={settingEvents}
              onSaveSettingEvents={onSaveSettingEvents}
            ></SettingEventsPalisisTable>
          </Spinner>
        )}
      </div>
    </div>
  );
};
export default SettingsEventPalisis;
